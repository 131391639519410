<template>
  <AppKebabActions :is-attached="false">
    <template #activator="{ on }">
      <v-avatar
        class="sourcery__avatar"
        size="40"
        v-on="on">
        <v-icon size="24">
          mdi-dots-horizontal
        </v-icon>
      </v-avatar>
    </template>

    <template>
      <div class="action">
        <CollectionInfo
          :item="item"
          @showInfo="showInfo" />
      </div>
      <div
        class="action">
        <collection-copy
          :item="item"
          :collection-id="item.id" />
      </div>
      <div class="action">
        <collection-add-to-custom-library
          :id="item.id"
          :item="item"
          :libraries-list="librariesList"
          :library-id="$route.params.id" />
      </div>
      <div
        class="action">
        <collection-btn-sharing-option
          :disabled="!(disableActions && !item.follow)" />
      </div>
      <div
        class="action">
        <CollectionDelete
          :disabled="!(disableActions && !item.follow)"
          is-library
          :item="item" />
      </div>
      <div class="action">
        <app-icon-btn
          :access="canWorkWithAddProduct"
          :icon-height="16"
          :show-tooltip="!canWorkWithAddProduct.allowed"
          icon-name="mdi-plus"
          bottom
          @click="openCreateProductModal">
          Create Product
        </app-icon-btn>
      </div>
      <div class="action">
        <EmbedCode />
      </div>
      <div class="action">
        <app-icon-btn
          icon-name="mdi-information-outline"
          :icon-height="16"
          @click="downloadExampleCSV">
          Download Example CSV Template
        </app-icon-btn>
      </div>
      <div class="action">
        <app-icon-btn
          :access="canWorkWithCSVProducts"
          :icon-height="16"
          :show-tooltip="!canWorkWithCSVProducts.allowed"
          icon-name="mdi-upload"
          bottom>
          <template v-if="canWorkWithCSVProducts.allowed">
            <FileUpload
              ref="uploadBulkCsv"
              v-model="files"
              :multiple="false"
              :drop="false"
              :drop-directory="false"
              input-id="uploadBulkCsv"
              @input-filter="inputFilter" />
            <label
              for="uploadBulkCsv"
              class="px-0">
              Upload CSV File
            </label>
          </template>
          <template v-else>
            Upload CSV File
          </template>
        </app-icon-btn>
      </div>
    </template>
  </AppKebabActions>
</template>
<script>
import API from '@/services/graphql';
import FileUpload from 'vue-upload-component';
import AppKebabActions from '@/components/App/AppKebabActions';
import { COLLECTION } from '@/constants/cores';
import CollectionDelete
  from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionDelete';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import CollectionCopy
  from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionCopy';
import CollectionAddToCustomLibrary
  from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionAddToCustomLibrary';
import CollectionBtnSharingOption from '@/components/Collections/CollectionBtnSharingOption';
import CollectionInfo from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/CollectionInfo';
import CollectionEmbedCode from '@/components/CollectionDetails/CollectionEmbedCode';
export default {
  name: 'CollectionActionDetail',
  components: {
    CollectionBtnSharingOption,
    CollectionAddToCustomLibrary,
    CollectionCopy,
    CollectionDelete,
    CollectionInfo,
    AppKebabActions,
    EmbedCode: CollectionEmbedCode,
    FileUpload,
  },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    isCommunityCollections: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      COLLECTION,
    };
  },
  computed: {
    ...mapState('Libraries', ['librariesList']),
    ...mapGetters('UserRoles', ['canAddProductToCollection', 'canUploadCSVProducts', 'findUserRoleInLibraryForActiveHeader']),
    listSharingName() {
      return this.item.members.map(e => e.name).join(', ');
    },
    canWorkWithAddProduct() {
      return this.canAddProductToCollection(this.findUserRoleInLibraryForActiveHeader);
    },
    canWorkWithCSVProducts() {
      return this.canUploadCSVProducts(this.findUserRoleInLibraryForActiveHeader);
    },
  },
  watch: {
    files(val) {
      if (val?.length) {
        this.uploadFile();
      }
    },
  },
  methods: {
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapActions(['handleError', 'declareAttachment', 'downloadFileByBlob']),
    ...mapActions({
      openCreateProductModal: 'Collections/openCreateProductModal',
      showCollectionInfo: 'Collections/showCollectionInfo',
      subscribeImportDocument: 'Collections/subscribeImportDocument',
    }),
    async showInfo() {
      await this.showCollectionInfo({
        collectionId: this.item.id,
        isCommunityCollections: this.isCommunityCollections,
      });
    },
    inputFilter(newFile, oldFile, prevent) {
      let val;
      if (newFile && !oldFile) {
        if (newFile.file && !newFile.file.type.includes('csv')) {
          this.handleError('Only CSV format is allowed to be downloaded');
          val = prevent();
        }
      } return val;
    },
    async uploadFile() {
      const { file, type, name: filename } = this.files[0];
      const { id: collection_id, libraryId: library_id } = this.item;
      this.spinner(true);
      try {
        await this.subscribeImportDocument({
          collectionId: collection_id,
        });
        await this.declareAttachment({
          file: {
            file,
            type,
          },
          initialMetadata: {
            operation: 'import',
            collection_id,
            library_id,
          },
          filename,
        });
        this.openSnackBar({
          text: 'Bulk product csv has been successfully uploaded. The products will appear in the collection after a while',
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async downloadExampleCSV() {
      this.spinner(true);
      try {
        const { data } = await API.getTemplateUrl({
          filename: 'products_import_template.csv',
        });
        const link = data.response;
        this.handleDownload(link);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    handleDownload(link) {
      const element = document.createElement('a');
      element.setAttribute('href', link);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
<style scoped>
.action {
  padding: 0 !important;
}
</style>
